<mat-card class="detail-card">
    <mat-card-header class="detail-card-header">
        <mat-card-title data-test="title">Partnership Investments</mat-card-title>
    </mat-card-header>
    <mat-card-subtitle>
        <ng-container *ngIf="(selectedFundInvestmentsForFundReport$ | async) as fundInvestmentsForFundReport">
            <valumize-info-panel *ngIf="fundInvestmentsForFundReport.status === 'ERROR'" [appearance]="'ERROR'"
                                 [message]="fundInvestmentsForFundReport.errorMessage"></valumize-info-panel>
            <valumize-info-panel *ngIf="fundInvestmentsForFundReport.status === 'SAVED'" [appearance]="'SAVED'" [message]="'Company successfully added'"></valumize-info-panel>
            <mat-progress-spinner *ngIf="fundInvestmentsForFundReport.status === 'LOADING'"></mat-progress-spinner>
        </ng-container>
    </mat-card-subtitle>
    <ng-container *ngIf="fundId$ | async as fundId">
        <ng-container *ngIf="(selectedFundReport$ | async)?.fundReport?.data?.id as fundReportId; else selectReportFirst">
            <ng-container *ngIf="fundReportId">
                <mat-card-content class="detail-card-content">
                    <ng-container *ngIf="partnershipInvestments$ | async as partnershipInvestments">
                        <section class="table-container" tabindex="0">
                            <form [formGroup]="partnershipInvestmentForm">
                                <table [dataSource]="partnershipInvestments.tableDatasource" mat-table>
                                    <ng-container matColumnDef="company" sticky>
                                        <th *matHeaderCellDef mat-header-cell>Company</th>
                                        <td *matCellDef="let element" [queryParams]="{
                                                    fundId: fundId,
                                                    fundReportId: fundReportId,
                                                    fundValuationId: fundValuationId,
                                                    fundInvestmentId: element.fundInvestmentId,
                                                    assetValuationId: element.assetValuationId}" [routerLink]="'../../assets/' + element.assetId"
                                            class="no-wrap"
                                            mat-cell>
                                            <ng-container *ngIf="element.company.source !== 'initial'; else companyrowunformated">
                                                <a>{{element.company | traceableFormat}}</a>
                                            </ng-container>
                                            <ng-template #companyrowunformated>
                                                {{element.company | traceableFormat}}
                                            </ng-template>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="activityIndustry">
                                        <th *matHeaderCellDef mat-header-cell>Activity/Industry</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GENERAL
                                        )" *matCellDef="let element"
                                            mat-cell>
                                            {{element.activityIndustry | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="country">
                                        <th *matHeaderCellDef mat-header-cell>Country</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GENERAL
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.country.code | codeFormat: codeTableCountry | async}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="gpIndication">
                                        <th *matHeaderCellDef mat-header-cell style="white-space: nowrap">GP Indication</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GENERAL
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.gpIndication.code | codeFormat: codeTableGpIndication | async}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="investmentDate">
                                        <th *matHeaderCellDef mat-header-cell style="white-space: nowrap">Inv. Date</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.EXITINFO
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.investmentDate | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="percentageHeld">
                                        <th *matHeaderCellDef mat-header-cell>% held</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GENERAL
                                        )" *matCellDef="let element" mat-cell>{{element.percentageHeld | traceableFormat}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="remainingCost">
                                        <th *matHeaderCellDef mat-header-cell>Remaining Cost</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GPVALUATION
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.remainingCost | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="gpNAV">
                                        <th *matHeaderCellDef mat-header-cell>GP NAV</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GPVALUATION
                                        )" *matCellDef="let element" mat-cell>
                                            <ng-container *ngIf="!isEditable; else editNAV">
                                                {{element.gpNAV | traceableFormat}}
                                            </ng-container>
                                            <ng-template #editNAV>
                                                <ng-container *ngIf="isEditableNavColumnCell(element.company.text)">
                                                    <input (click)="$event.stopPropagation()"
                                                           formControlName="{{createFormControlName(element.company.text, 'GpNAV')}}"
                                                           id="{{createFormControlName(element.company.text, 'GpNAV')}}"
                                                           matInput
                                                           type="number">
                                                </ng-container>
                                                <ng-container *ngIf="!isEditableNavColumnCell(element.company.text)">
                                                    {{element.gpNAV | traceableFormat}}
                                                </ng-container>
                                            </ng-template>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="unrealizedTVPI">
                                        <th *matHeaderCellDef mat-header-cell>Unrealized TVPI</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GPVALUATION
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.unrealizedTVPI | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="totalTVPI">
                                        <th *matHeaderCellDef mat-header-cell>Total TVPI</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GPVALUATION
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.totalTVPI | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="exitDateAssumption">
                                        <th *matHeaderCellDef mat-header-cell style="white-space: nowrap">Exit Date <br/> Assumption</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.EXITINFO
                                        )" *matCellDef="let element" mat-cell>
                                            <ng-container *ngIf="!isEditable; else editExitDateAssumption">
                                                {{element.exitDateAssumption | traceableFormat}}
                                            </ng-container>
                                            <ng-template #editExitDateAssumption>
                                                <ng-container *ngIf="isEditableCell(element.company.text)">
                                                    <input (click)="$event.stopPropagation()"
                                                           [matDatepicker]="ExitDateAssumptionDatePicker"
                                                           formControlName="{{createFormControlName(element.company.text, 'ExitDateAssumption')}}"
                                                           id="{{createFormControlName(element.company.text, 'ExitDateAssumption')}}"
                                                           matInput>
                                                    <mat-datepicker-toggle [for]="ExitDateAssumptionDatePicker" matIconSuffix></mat-datepicker-toggle>
                                                    <mat-datepicker #ExitDateAssumptionDatePicker></mat-datepicker>
                                                </ng-container>
                                                <ng-container *ngIf="!isEditableCell(element.company.text)">
                                                    {{element.exitDateAssumption | traceableFormat}}
                                                </ng-container>
                                            </ng-template>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="discountRate">
                                        <th *matHeaderCellDef mat-header-cell>Discount Rate</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.DMV
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.discountRate | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="selectedBid">
                                        <th *matHeaderCellDef mat-header-cell>Selected Bid</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.DMV
                                        )" *matCellDef="let element" mat-cell>
                                            <ng-container *ngIf="!isEditable; else editBidPriceAdjustment">
                                                {{element.selectedBid | traceableFormat}}
                                            </ng-container>
                                            <ng-template #editBidPriceAdjustment>
                                                <ng-container *ngIf="isEditableBidColumnCell(element.company.text) && !!fundValuationId">
                                                    <input (click)="$event.stopPropagation()"
                                                           formControlName="{{createFormControlName(element.company.text, 'SelectedBid')}}"
                                                           id="{{createFormControlName(element.company.text, 'SelectedBid')}}"
                                                           matInput
                                                           type="number">
                                                </ng-container>
                                                <ng-container *ngIf="!isEditableBidColumnCell(element.company.text)">
                                                    {{element.selectedBid | traceableFormat}}
                                                </ng-container>
                                            </ng-template>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="discountToNAV">
                                        <th *matHeaderCellDef mat-header-cell>Discount to NAV</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.DMV
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.discountToNAV | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="percentageOfTotalBid">
                                        <th *matHeaderCellDef mat-header-cell>% of total Bid</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.DMV
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.percentageOfTotalBid | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="percentageOfTotalNAV">
                                        <th *matHeaderCellDef mat-header-cell>% of total NAV</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.GPVALUATION
                                        )" *matCellDef="let element" mat-cell>
                                            {{element.percentageOfTotalNAV | traceableFormat}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="stabilityOfCFs">
                                        <th *matHeaderCellDef mat-header-cell>Stability of CFs</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.DMV
                                        )"
                                            *matCellDef="let element" class="{{setBackgroundColor(element.stabilityOfCFs.code)}}"
                                            mat-cell>
                                            {{element.stabilityOfCFs.code | codeFormat: codeTableAssessment | async}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cashflowLow">
                                        <th *matHeaderCellDef mat-header-cell>Low Case</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.DMV
                                        )" *matCellDef="let element" mat-cell>
                                            <ng-container *ngIf="!isEditable; else editCashflowLow">
                                                {{getDisplayScenarioValue(element.cashflowLow, partnershipInvestments.partnershipInvestment.netCurrentAssets) | traceableFormat}}
                                            </ng-container>
                                            <ng-template #editCashflowLow>
                                                <ng-container *ngIf="isEditableCell(element.company.text)">
                                                    <input (click)="$event.stopPropagation()"
                                                           formControlName="{{createFormControlName(element.company.text, 'CashflowLow')}}"
                                                           id="{{createFormControlName(element.company.text, 'CashflowLow')}}"
                                                           matInput
                                                           type="number">
                                                </ng-container>
                                                <ng-container *ngIf="!isEditableCell(element.company.text)">
                                                    {{element.cashflowLow | traceableFormat}}
                                                </ng-container>
                                            </ng-template>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cashflowBase">
                                        <th *matHeaderCellDef mat-header-cell>Base Case</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.DMV
                                        )" *matCellDef="let element" mat-cell>
                                            <ng-container *ngIf="!isEditable; else editCashflowBase">
                                                {{getDisplayScenarioValue(element.cashflowBase, partnershipInvestments.partnershipInvestment.netCurrentAssets) | traceableFormat}}
                                            </ng-container>
                                            <ng-template #editCashflowBase>
                                                <ng-container *ngIf="isEditableCell(element.company.text)">
                                                    <input (click)="$event.stopPropagation()"
                                                           formControlName="{{createFormControlName(element.company.text, 'CashflowBase')}}"
                                                           id="{{createFormControlName(element.company.text, 'CashflowBase')}}"
                                                           matInput
                                                           type="number">
                                                </ng-container>
                                                <ng-container *ngIf="!isEditableCell(element.company.text)">
                                                    {{element.cashflowBase | traceableFormat}}
                                                </ng-container>
                                            </ng-template>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cashflowHigh">
                                        <th *matHeaderCellDef mat-header-cell>High Case</th>
                                        <td (click)="openAssetDMVValuationDialog(
                                        element.assetId,
                                        element.assetValuationId,
                                        fundId,
                                        fundReportId,
                                        fundValuationId!,
                                        element.fundInvestmentId,
                                        assetDialogExpandType.DMV
                                        )" *matCellDef="let element" mat-cell>
                                            <ng-container *ngIf="!isEditable; else editCashflowHigh">
                                                {{getDisplayScenarioValue(element.cashflowHigh, partnershipInvestments.partnershipInvestment.netCurrentAssets) | traceableFormat}}
                                            </ng-container>
                                            <ng-template #editCashflowHigh>
                                                <ng-container *ngIf="isEditableCell(element.company.text)">
                                                    <input (click)="$event.stopPropagation()"
                                                           formControlName="{{createFormControlName(element.company.text, 'CashflowHigh')}}"
                                                           id="{{createFormControlName(element.company.text, 'CashflowHigh')}}"
                                                           matInput
                                                           type="number">
                                                </ng-container>
                                                <ng-container *ngIf="!isEditableCell(element.company.text)">
                                                    {{element.cashflowHigh | traceableFormat}}
                                                </ng-container>
                                            </ng-template>
                                        </td>
                                    </ng-container>
                                    <tr *matHeaderRowDef="partnershipInvestments.tableColumns" mat-header-row></tr>
                                    <tr *matRowDef=" let row; columns: partnershipInvestments.tableColumns"
                                        class="{{styleRowImportant(row)}} {{styleRowBorderTop(row)}} {{styleRowNotClickable(row)}} {{styleRowExited(row)}}"
                                        mat-row
                                    ></tr>
                                </table>
                            </form>
                        </section>
                        <mat-expansion-panel [expanded]="panelOpenState" class="mat-expansion-panel">
                            <mat-expansion-panel-header class="mat-expansion-panel-header">
                                <mat-card-title data-test="title">Current and Realized Investments</mat-card-title>
                            </mat-expansion-panel-header>
                            <section class="table-container" tabindex="0">
                                <table [dataSource]="partnershipInvestments.performanceTotals" mat-table>
                                    <ng-container matColumnDef="company" sticky>
                                        <th *matHeaderCellDef mat-header-cell></th>
                                        <td *matCellDef="let element" class="no-wrap" mat-cell>{{element.company | traceableFormat}}</td>
                                        <td *matFooterCellDef
                                            mat-footer-cell>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.company | traceableFormat}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="realizedCost">
                                        <th *matHeaderCellDef mat-header-cell>Realized Cost</th>
                                        <td *matCellDef="let element" mat-cell>{{element.realizedCost | traceableFormat}}</td>
                                        <td *matFooterCellDef
                                            mat-footer-cell>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.realizedCost | traceableFormat}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="realizedGains">
                                        <th *matHeaderCellDef mat-header-cell>Realized Gains</th>
                                        <td *matCellDef="let element" mat-cell>{{element.realizedGains | traceableFormat}}</td>
                                        <td *matFooterCellDef
                                            mat-footer-cell>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.realizedGains | traceableFormat}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="totalCost">
                                        <th *matHeaderCellDef mat-header-cell>Total Cost</th>
                                        <td *matCellDef="let element" mat-cell>{{element.totalCost | traceableFormat}}</td>
                                        <td *matFooterCellDef
                                            mat-footer-cell>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.totalCost | traceableFormat}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="totalRealized">
                                        <th *matHeaderCellDef mat-header-cell>Total Realized</th>
                                        <td *matCellDef="let element" mat-cell>{{element.totalRealized | traceableFormat}}</td>
                                        <td *matFooterCellDef
                                            mat-footer-cell>{{partnershipInvestments.performanceTotalCurrentAndRealizedInvestments.totalRealized | traceableFormat}}</td>
                                    </ng-container>

                                    <tr *matHeaderRowDef="partnershipInvestments.performanceTotalColumns" mat-header-row></tr>
                                    <tr *matRowDef=" let row; columns: partnershipInvestments.performanceTotalColumns" class="not-clickable" mat-row></tr>
                                    <tr *matFooterRowDef="partnershipInvestments.performanceTotalColumns" class="row-important row-top-border" mat-footer-row></tr>
                                </table>
                            </section>
                            <valumize-fund-current-and-realized-investments
                                [columnDefinition]="partnershipInvestments.currentAndRealizedInvestmentColumns"
                                [dataSource]="partnershipInvestments.currentInvestments"
                                [fundId]="fundId"
                                [fundReportId]="fundReportId"
                                [fundValuationId]="fundValuationId"
                                [title]="'Current Investments'">
                            </valumize-fund-current-and-realized-investments>
                            <valumize-fund-current-and-realized-investments
                                [columnDefinition]="partnershipInvestments.currentAndRealizedInvestmentColumns"
                                [dataSource]="partnershipInvestments.realizedInvestments"
                                [fundId]="fundId"
                                [fundReportId]="fundReportId"
                                [fundValuationId]="fundValuationId"
                                [title]="'Realized Investments'">
                            </valumize-fund-current-and-realized-investments>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-card-content>
                <mat-card-actions class="detail-card-actions">
                    <button (click)="openManageFundInvestmentsDialog(fundId, fundReportId)"
                            color="primary"
                            data-test="manage-fund-investments"
                            mat-stroked-button>
                        <mat-icon>settings</mat-icon>
                        Manage Investments
                    </button>
                    <button (click)="editMode()"
                            *ngIf="!isEditable"
                            [disabled]="(isEditDisabled$ | async)!"
                            color="primary"
                            data-test="edit-button"
                            mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button (click)="save()"
                            *ngIf="isEditable"
                            [disabled]="partnershipInvestmentForm.invalid"
                            color="primary"
                            data-test="save-button"
                            mat-icon-button>
                        <mat-icon>save</mat-icon>
                    </button>
                    <button (click)="cancel()"
                            *ngIf="isEditable"
                            color="primary"
                            data-test="cancel-button"
                            mat-icon-button>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-card-actions>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #selectReportFirst>
        <mat-card-content class="detail-card-content" style="color: grey">
            Please select fund report first!
        </mat-card-content>
    </ng-template>
</mat-card>
